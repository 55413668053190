import { RoleType } from './interface'

export const DEFAULT_LENGTH_JSON_RENDER = 200
export const ROLE: RoleType = {
  Admin: 'Admin',
  Developer: 'Developer',
  AccountManager: 'Account Manager',
  DeveloperAccountManager: 'Developer - Account Manager',
}

export const KIND = {
  CARRIERS: 'carriers',
  AGENCIES: 'agencies',
  ALL: 'all',
  ADMIN: 'admin',
}

export const ERROR_RES = {
  ENTITY_ALREADY: 'Entity already exists.',
}

export const styleJsonPacket = {
  display: '-webkit-box',
  WebkitLineClamp: '2',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitBoxOrient: 'vertical',
} as object

export const SubjectActionType = [
  { id: 'TEXT_EMAIL', name: 'Renewals Text and Email' },
  { id: 'TEXT', name: 'Renewals Text' },

  { id: 'EMAIL', name: 'Renewals Email' },
]
export const ConfigurationAvoidKeyName = [
  { id: 'EMAIL', name: 'Email' },
  { id: 'PHONE_NUMBER', name: 'Phone Number' },
]

export const ConfigurationDateSettingKeyName = [
  { id: 't1', name: 't1' },
  { id: 't2', name: 't2' },
  { id: 't3', name: 't3' },
  { id: 't4', name: 't4' },
  { id: 't5', name: 't5' },
  { id: 't6', name: 't6' },
  { id: 't7', name: 't7' },
  { id: 't8', name: 't8' },
  { id: 't9', name: 't9' },
  { id: 't10', name: 't10' },
]

export const ConfigurationTypeName = [
  { id: 'AVOID_SETTING', name: 'Avoid Setting', display: 'Avoid' },
  { id: 'DATE_SETTING', name: 'Date Setting', display: 'T-Day' },
]

export const MAIN_MODULE = {
  DEVELOPER_PORTAL: {
    name: 'DEVELOPER_PORTAL',
    displayName: 'Developer portal',
    path: '/developer-portal',
  },
  ADMIN_PORTAL: {
    name: 'ADMIN_PORTAL',
    displayName: 'Admin portal',
    path: '/admin-portal',
  },
  CHATLIO: {
    name: 'CHATLIO',
    displayName: 'Chatlio',
    path: '/chatlio',
  },
  QUICK_START: {
    name: 'QUICK_START',
    displayName: 'Quick start',
    path: '/quick-start',
  },
  DEVELOPER_DOCS: {
    name: 'DEVELOPER_DOCS',
    displayName: 'Developer docs',
    path: '/developer-docs',
  },
  TECH_SOLUTION: {
    name: 'TECH_SOLUTION',
    displayName: 'Tech solution',
    path: '/tech-solution',
  },
  MY_ACCOUNT: {
    name: 'MY_ACCOUNT',
    displayName: 'My account',
    path: '/my-account',
  },
}

export const SUB_MODULE = {
  SYSTEM_MONITORING: {
    name: 'SYSTEM_MONITORING',
    displayName: 'System monitoring',
    path: '',
  },
  USER_MANAGEMENT: {
    name: 'USER_MANAGEMENT',
    displayName: 'User management',
    path: '',
  },
  SYSTEM_CONFIGURATION: {
    name: 'WEBHOOK_MANAGEMENT',
    displayName: 'System configuration',
    path: '',
  },
  PRINCIPAL_ACCOUNT_MANAGEMENT: {
    name: 'PRINCIPAL_ACCOUNT_MANAGEMENT',
    displayName: 'Principal account management',
    path: '',
  },
  EMAIL_SYSTEM: {
    name: 'EMAIL_SYSTEM',
    displayName: 'Email system',
    path: '',
  },
  ACTIVITY_MANAGEMENT: {
    name: 'ACTIVITY_MANAGEMENT',
    displayName: 'Activity management',
    path: '',
  },
  DEVELOPMENT_MANAGEMENT: {
    name: 'DEVELOPMENT_MANAGEMENT',
    displayName: 'Development management',
    path: '',
  },
  MY_AGENCY: {
    name: 'MY_AGENCY',
    displayName: 'My Agency',
    path: '',
  },
  INFORMATION: {
    name: 'INFORMATION',
    displayName: 'Information',
    path: '',
  },
  CONVERSATION: {
    name: 'CONVERSATION',
    displayName: 'Conversation',
    path: '',
  },
  API_DOCS: {
    name: 'API_DOCS',
    displayName: 'API Docs',
    path: '',
  },
  API_SANDBOX: {
    name: 'API_SANDBOX',
    displayName: 'API Sandbox',
    path: '',
  },
}

export const EMAIL_JOB_STATUS = [
  { id: 'START_STREAM', name: 'START_STREAM' },
  { id: 'PENDING', name: 'PENDING' },
  { id: 'DELIVERED', name: 'DELIVERED' },
  { id: 'CANCEL_STREAM', name: 'CANCEL_STREAM' },
  { id: 'FAILED', name: 'FAILED' },
  { id: 'BOND_UNPAID_CANCELLED', name: 'BOND_UNPAID_CANCELLED' },
  { id: 'BOND_PAID_CANCELLED', name: 'BOND_PAID_CANCELLED' },
  { id: 'BOND_CANCELLED', name: 'BOND_CANCELLED' },
]
